import { motion } from 'framer-motion';
import celeb1 from '../assets/celeb/Celeb1.png';
import celeb2 from '../assets/celeb/Celeb2.png';
import celeb3 from '../assets/celeb/Celeb3.png';



const CelebrityShowcase = () => {
  const celebrities = [
    { id: 1, image: celeb1, name: 'Rajinikanth', event: 'Wedding Reception' },
    { id: 2, image: celeb2, name: 'Sivakumar & Family', event: 'Engagement' },
    { id: 3, image: celeb3, name: 'Surya, Jyotika & Karti', event: 'Wedding Ceremony' },

  ];

  return (
    <section id="celebrities" className="relative min-h-screen py-12 md:py-20 overflow-hidden bg-gradient-to-b from-[#0A0A0F] via-[#12121A] to-[#1A1A1F]">
      {/* Decorative Elements */}
      <div className="absolute top-0 left-0 w-1/3 h-1/3 bg-gradient-to-r from-blue-500/10 to-purple-500/10 blur-[100px] rounded-full" />
      <div className="absolute bottom-0 right-0 w-1/3 h-1/3 bg-gradient-to-l from-emerald-500/10 to-teal-500/10 blur-[100px] rounded-full" />
      
      {/* Section Title */}
      <div className="text-center mb-8 md:mb-16 px-4 md:px-6">
        <motion.p 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-base md:text-lg font-medium mb-3"
          style={{
            background: 'linear-gradient(to right, #60A5FA, #D8B4FE, #6EE7B7)',  // New gradient
            WebkitBackgroundClip: 'text',
            backgroundClip: 'text',
            color: 'transparent',
          }}
        >
          Our Distinguished Guests
        </motion.p>
        <motion.h2 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.1 }}
          className="text-4xl md:text-6xl font-bold"
          style={{
            background: 'linear-gradient(to right, #60A5FA, #D8B4FE, #6EE7B7)',  // New gradient
            WebkitBackgroundClip: 'text',
            backgroundClip: 'text',
            color: 'transparent',
            fontFamily: 'Inter, sans-serif',
            paddingBottom: '1rem'
          }}
        >
          Celebrity Celebrations
        </motion.h2>
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="w-24 h-1 mx-auto mt-6 rounded-full bg-gradient-to-r from-blue-500 via-purple-500 to-emerald-500"
        />
      </div>

      {/* Updated Infinite Scroll Container */}
      <div className="relative w-full">
        <motion.div 
          className="flex gap-3 md:gap-6 py-4"
          animate={{
            x: [0, -1920],
          }}
          transition={{
            x: {
              repeat: Infinity,
              repeatType: "loop",
              duration: 40, // Slowed down a bit
              ease: "linear",
            },
          }}
        >
          {[...celebrities, ...celebrities].map((celeb, index) => (
            <motion.div
              key={`${celeb.id}-${index}`}
              className="relative group flex-shrink-0"
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
            >
              <div className="relative w-[280px] md:w-[320px] aspect-[3/4] overflow-hidden rounded-2xl">
                <img 
                  src={celeb.image} 
                  alt={celeb.name}
                  loading="lazy"
                  className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-110"
                />
                
                {/* Updated Gradient Overlay */}
                <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/20 to-transparent opacity-60 group-hover:opacity-90 transition-opacity duration-300" />
                
                {/* Updated Content Positioning */}
                <div className="absolute inset-0 flex items-end p-4 md:p-6 opacity-100 md:opacity-0 md:group-hover:opacity-100 transition-opacity duration-300">
                  <div className="w-full">
                    <span className="text-xs md:text-sm font-medium text-blue-400">{celeb.event}</span>
                    <h3 className="text-white text-lg md:text-xl font-semibold mt-1 md:mt-2 mb-2" 
                        style={{ fontFamily: 'Inter, sans-serif' }}>{celeb.name}</h3>
                    <div className="h-0.5 w-12 bg-gradient-to-r from-blue-500 via-purple-500 to-emerald-500" />
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  );
};

export default CelebrityShowcase; 