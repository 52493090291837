import { motion } from 'framer-motion';
import heroImage from '../assets/image.png';
import mandapImage from '../assets/Mandap.png';

const Hero = () => {
  return (
    <div className="min-h-screen w-full overflow-hidden relative">
      {/* Mandap background image */}
      <div 
        className="absolute inset-0 z-0"
        style={{
          backgroundImage: `url(${mandapImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          filter: 'brightness(0.5) contrast(1.1)'
        }}
      />
      
      {/* Translucent overlay */}
      <div 
        className="absolute inset-0 z-0"
        style={{
          backgroundColor: 'rgba(10, 10, 15, 0.5)',
          backdropFilter: 'blur(0px)'
        }}
      />

      {/* Content - keeping same position, adjusting only mobile text sizes */}
      <div className="relative z-10 w-full px-8 md:px-16 pt-32">
        <motion.h1 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          style={{
            fontFamily: 'Inter, sans-serif',
            fontWeight: '900',
            fontSize: 'clamp(60px, 15vw, 180px)',
            backgroundImage: `url(${heroImage})`,
            backgroundSize: '200% 100%',
            backgroundPosition: 'center',
            backgroundClip: 'text',
            WebkitBackgroundClip: 'text',
            color: 'transparent',
            filter: 'brightness(1.2) contrast(1.1)',
            animation: 'moveBackground 4s ease-in-out infinite alternate'
          }}
          className="mb-8"
        >
          Creating
        </motion.h1>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="space-y-3"
        >
          <h2 
            className="font-medium tracking-wide bg-gradient-to-r from-purple-100 via-purple-200 to-purple-300 bg-clip-text text-transparent text-[clamp(2rem,4vw,4rem)]"
            style={{ 
              textShadow: '0 0 20px rgba(0,0,0,0.1)',
              paddingBottom: '10px',
              lineHeight: '1.2'
            }}
          >
            Unforgettable Wedding Experiences
          </h2>
          <h3 
            className="font-medium tracking-wide bg-gradient-to-r from-purple-100 via-purple-200 to-purple-300 bg-clip-text text-transparent text-[clamp(2rem,4vw,4rem)]"
            style={{ 
              textShadow: '0 0 20px rgba(0,0,0,0.1)',
              paddingBottom: '10px',
              lineHeight: '1.2'
            }}
          >
            Through Timeless Elegance
          </h3>
        </motion.div>
      </div>
    </div>
  );
};

// Add this CSS either in your global styles or as a styled-component
const styles = `
  @keyframes moveBackground {
    0% {
      background-position: 0% 0%;
    }
    50% {
      background-position: 100% 100%;
    }
    100% {
      background-position: 0% 0%;
    }
  }
`;

// Add this style tag to your component or global styles
const styleSheet = document.createElement("style");
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default Hero; 
